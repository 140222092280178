import React from 'react';

import * as classes from '../styles/about.module.scss';
import section1img from '../images/about_section_1.svg';
import section2img from '../images/about_section_2.svg';
import section3img from '../images/about_section_3.svg';
import section4img from '../images/about_section_4.svg';
import Layout from '../components/Layout/Layout';
import linkedInLinkIcon from '../images/linkedInLink.svg';
import Typography from '../components/Typography/Typography';
import { graphql, useStaticQuery } from "gatsby";

import SEO from '../components/Seo';

export default function about() {
  const { allPrismicTeamlist } = useStaticQuery(graphql`
    {
      allPrismicTeamlist {
        edges {
          node {
            data {
              id {
                avatar {
                  url 
                }
                designation {
                  text
                }
                linkedin {
                  url
                }
                title {
                  text
                }
              }
            }
          }
        }
      }
    }
  `);

  const teamMembers = allPrismicTeamlist.edges.flatMap(edge =>
    edge.node.data.id.map(member => ({
      avatar: {
        url: member.avatar.url,
      },
      designation: member.designation,
      linkedin: member.linkedin,
      title: member.title
    }))
  );

  if (teamMembers.length === 0) {
    return <div>No team members to display.</div>;
  }

  return (
    <Layout>
      <SEO title="About" />
      <div className={classes.root}>
        <div className={classes.main_container}>
          <div>
            <Typography variant="h1" align="center">
              About Experts Circle
            </Typography>
          </div>
          <section className={`${classes.section} ${classes.section2}`}>
            <div className={classes.section_content}>
              <img className={classes.section2img} src={section2img} alt="About" />
              <p className={`${classes.description} ${classes.section2description}`}>
                <Typography variant="p">
                  Experts Circle taps into the vast potential of seasoned professionals across industries, equipping them to use their rich qualifications and networks to drive meaningful connections between talent and opportunities. This platform revolutionises job matching, ensuring each candidate is meticulously assessed and endorsed by individuals who truly understand the nuances of the required competencies. Here, employers gain swift access to exceptional talent, while experts enjoy the dual benefit of contributing their industry insights and earning supplemental income. The majority of the placement fee recognises the expert's dedication to quality, creating a rewarding experience for every link in the hiring chain.
                </Typography>
              </p>
            </div>
          </section>
          <section className={`${classes.section} ${classes.section3}`}>
            <div className={classes.section_content}>
              <div className={classes.section3_header}>
                <Typography variant="h2">Our Story</Typography>

                <Typography variant="h3" align="center">
                  Experts Circle was founded to revolutionise how talent and opportunities connect. Our story is driven by the vision and experiences of our founder.
                </Typography>
              </div>
              <div className={classes.seciton3_main_content}>
                <img className={classes.section3img} src={section3img} alt="About" />
                <div className={classes.section3mainText}>
                  <div className={classes.description}>
                    <Typography variant="p">
                      Experts Circle was born out of a determination to bring fairness and precision to the hiring process. Our founder, Robiul Islam, experienced first-hand the inefficiencies and unethical practices in traditional methods.
                    </Typography>
                    <Typography variant="p">
                      With a background in software engineering and recruitment technology, Robiul envisioned a platform that would unite advanced tools with the human insight of industry specialists. This vision became Experts Circle—a space where talent, expertise, and opportunity converge seamlessly.
                    </Typography>
                    <Typography variant="p">
                      Today, Experts Circle is reshaping how businesses hire and how professionals grow. We remain committed to building trust, transparency, and success in every connection we facilitate.
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className={`${classes.section} ${classes.section4}`}>
            <div className={classes.section_content}>
              <div className={classes.section4_left_info}>
                <Typography variant="h2">Our Mission</Typography>
                <Typography variant="p">
                  Our mission at Experts Circle is to empower industry specialists to drive impactful matches between talent and employers. By utilising their insight and experience, we ensure candidates are thoroughly evaluated and perfectly aligned with roles, fostering faster, smarter hiring.
                </Typography>
              </div>
              <img className={classes.section4img} src={section4img} alt="About" />
            </div>
          </section>
          <section className={`${classes.section}`}>
            <Typography variant="h2" align="center">
              Our Team
            </Typography>
            <div className={classes.people_card_list}>
              {teamMembers.map((people) => {
                return (
                  <div className={classes.people_card} key={people.title.text}>
                    <a
                      className={classes.inkedinProfileLink}
                      rel="noreferrer"
                      href={people.linkedin.url}
                      target="_blank"
                    >
                      <img src={linkedInLinkIcon} alt="LinkedIn profile" />
                    </a>
                    <div className={classes.people_avater}>
                      <img src={people.avatar.url} alt={people.title.text} />
                    </div>
                    <div className={classes.people_main_info}>
                      <h4>{people.title.text}</h4>
                      <p>{people.designation.text}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </section>
        </div>
      </div>
    </Layout>
  );
}
